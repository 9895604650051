//PLACEHOLDER
input:focus::placeholder {
    color: transparent;
}

.CasDocumentationField, .CasNotesField {
    background: #eeeeee;
    border-radius: 4px;
    padding: 4px 8px;
    margin-top: 4px;
    p {
        font-size: .85rem; 
    }
    &.CasNotesField {
        p {
            white-space: pre-line;
        }
    }
    &.CasCMEFeederIndexReport {
        white-space: pre;
        p {
            overflow: overlay;
            font-family: monospace;
            font-size: 12px;
            text-wrap: nowrap;
        }
    }
}

.cas-horizontal-field{
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    align-items: center;
}

input::file-selector-button {
    border: 1px solid rgba(136, 153, 136, 0.7);
    padding: 6px 10px;
    border-radius: 3px;
    font-weight: bold;
    color: rgb(53, 75, 53);
    font-family: "Public Sans", sans-serif;
    background: white;
    cursor: pointer;
    &:hover {
        background: #eee;
    }
}